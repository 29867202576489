import React from 'react'
import { aboutSectionContentThree } from '../../data/about'

export const AboutSectionThree = () => {
  return (
      <section className='h-auto relative'>
          <div className='absolute  z-[-1] h-[100%] w-[100%]'></div>
          {/* <div className='absolute gradient z-[-1] h-[100%] w-[100%]'></div> */}
          <div className='lg:text-center p-5 py-14'>
              <h2 className='text-[35px] md:text-[42px] font-[700] lg:w-2/3 mx-auto'><span className='text-[#0361FE]'>{aboutSectionContentThree.title.coloured}</span>{aboutSectionContentThree.title.normal}</h2>
              <p className='text-[16px] font-[400] lg:w-2/3 mx-auto py-5 xl:py-7'>{aboutSectionContentThree.smallText}</p>
              <p className='text-[18px] font-[400] lg:w-5/6 mx-auto leading-[30px]'>{aboutSectionContentThree.paraLine}</p>
            </div>
    </section>
  )
}
